import styled from 'styled-components';
import primitives from '@veneer/primitives';
export const Container = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;

    @media (max-width: 575px) {
      margin-top: 8px;
    }
  }
`;

export const LeftContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: fit-content;

    svg {
      min-width: 15px;
      min-height: 15px;
      margin-top: 3px;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  ul {
    list-style: disc;
    margin-top: 12px;
    margin-left: 30px;

    li {
      font-size: 14px;
      font-family: ${primitives.typography.family0};
      color: ${primitives.color.gray12};
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const VerticalLine = styled.div`
  && {
    width: 2px;
    background-color: #d9d9d9;
    border-radius: 2px;
    margin-top: 18px;
  }
`;

export const Step = styled.h1`
  && {
    font-size: 16px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 24px;
    margin-left: 12px;
  }
`;

export const Substep = styled.h2`
  && {
    font-size: 14px;
    font-family: ${primitives.typography.family0};
    color: ${primitives.color.gray12};
    font-weight: 400;
    line-height: 20px;
  }
`;
