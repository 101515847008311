import React, { useCallback, useEffect, useRef, useState } from 'react';
import CircleIcon from '@veneer/core/dist/scripts/icons/icon_circle';

import {
  Container,
  LeftContainer,
  RightContainer,
  VerticalLine,
  Step
} from './styles';

interface StepperProps {
  stepTitle: string | JSX.Element;
  topics: string[];
  isLastTopic?: boolean;
  index: number;
}

export const Stepper: React.FC<StepperProps> = ({
  stepTitle,
  topics,
  isLastTopic,
  index
}) => {
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const [leftContainerHeight, setLeftContainerHeight] = useState(0);
  const BASE_SPACING = 36;

  const updateHeight = useCallback(() => {
    const element = rightContainerRef.current;
    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const lineHeightStr = computedStyle.lineHeight;
      let lineHeight = parseFloat(lineHeightStr);
      if (isNaN(lineHeight)) {
        lineHeight = 0;
      }

      let newHeight = element.clientHeight;
      if (newHeight <= lineHeight) {
        newHeight = 0;
      } else {
        newHeight = newHeight - BASE_SPACING;
      }
      setLeftContainerHeight(newHeight);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateHeight);
    if (rightContainerRef.current) {
      resizeObserver.observe(rightContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [leftContainerHeight, updateHeight]);

  return (
    <Container>
      <LeftContainer>
        <CircleIcon filled size={15} color="black" />
        {!isLastTopic && (
          <VerticalLine style={{ height: leftContainerHeight }} />
        )}
      </LeftContainer>
      <RightContainer
        ref={rightContainerRef}
        id={`stepper-right-container-${index}`}
      >
        {typeof stepTitle === 'string' ? (
          <Step dangerouslySetInnerHTML={{ __html: stepTitle }} />
        ) : (
          <Step>{stepTitle}</Step>
        )}
        {topics && (
          <div style={{ listStyle: 'disc' }}>
            <ul>
              {topics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
          </div>
        )}
      </RightContainer>
    </Container>
  );
};
