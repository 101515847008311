import React from 'react';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { useGetText } from '../../hooks/useGetText';
import { TitleContainer, Circle, CardTitleContainer } from './styles';

interface PlanNameProps {
  planName?: string;
  circlesNumber?: number;
  dataTestId?: string;
  isInkPaper?: boolean;
  isZeroPlan?: boolean;
  isYearly?: boolean;
}

export const PlanName: React.FC<PlanNameProps> = ({
  planName,
  circlesNumber = 0,
  dataTestId,
  isZeroPlan,
  isYearly
}) => {
  const { program, isYearlyPlanSelected } = useDashboardPlansState();
  const getText = useGetText();
  const text = isZeroPlan
    ? 'plans_selector.pay_as_you_print.card_header'
    : `plans_selector.common.plans_frequency.${planName}`;

  const getProgram = () => {
    if (isYearly || isYearlyPlanSelected) {
      return 'yearly_plan';
    }

    if (program === 'i_ink_paper') {
      return 'i_ink';
    }

    return program;
  };

  return (
    <CardTitleContainer isYearly={isYearly}>
      {Array.from({ length: circlesNumber }, (_, i) => {
        return (
          <Circle
            key={i}
            filled
            size={12}
            $isZeroPlan={isZeroPlan}
            program={getProgram()}
          />
        );
      })}
      {planName && program != 'i_toner' ? (
        <TitleContainer
          isYearly={isYearly}
          data-testid={`${dataTestId}-plan-name-title`}
        >
          <h1>{getText(`${text}`)}</h1>
        </TitleContainer>
      ) : (
        <div style={{ height: 30 }} />
      )}
    </CardTitleContainer>
  );
};
