import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';

import { Stepper } from './components/Stepper';
import {
  Container,
  Title,
  Subtitle,
  DividingLine,
  HeaderImage,
  PaymentLink
} from './styles';
import { JarvisARN } from './components/JarvisARN';
import { BillingCvv } from './components/BillingCvv';
import { useGetText } from '../../../common/hooks/useGetText';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import ARNImage from '../../../../imgs/yearly-plans-arn-modal.png';
import { useDispatch } from '../../hooks/useDispatch';
import { setCurrentStepAction } from '../../actions/setCurrentStepAction';
import { BILLING_FORM_STEP } from '../../../common/constants';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';

export const ConfirmYearlyChange: React.FC<{
  paymentMethodIsNotUpdated: boolean;
}> = ({ paymentMethodIsNotUpdated }) => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_min_768px})`
  );
  const {
    creditCard,
    paymentType,
    currentCycleEndDate,
    nextCycleStartDate,
    nextCycleEndDate,
    selectedYearlyPlan,
    disableCvvFormForYearlyPlan
  } = useYearlyPlanSelectorState();
  const shouldShowCvvCard =
    !disableCvvFormForYearlyPlan &&
    paymentType === 'credit_card' &&
    paymentMethodIsNotUpdated;

  const chargeStep = (): JSX.Element => {
    return (
      <>
        {paymentType === 'credit_card'
          ? getText(
              'plans_selector.yearly_confirmation.modals.charge_information',
              {
                charge_value: selectedYearlyPlan.price,
                charge_date: nextCycleStartDate,
                card: creditCard.cardTypeKey.toUpperCase(),
                card_end: creditCard.cardNumber.slice(-4)
              }
            )
          : getText(
              'plans_selector.yearly_confirmation.modals.non_credit_card_charge_information',
              {
                charge_value: selectedYearlyPlan.price,
                charge_date: nextCycleStartDate,
                payment_method:
                  paymentType === 'pay_pal'
                    ? 'PayPal'
                    : paymentType === 'direct_debit'
                    ? 'Direct Debit'
                    : ''
              }
            )}
        &nbsp;
        <PaymentLink
          data-testid="use-another-payment-method-link"
          data-linkid="ii_plans"
          onClick={() => dispatch(setCurrentStepAction(BILLING_FORM_STEP))}
        >
          {getText('plans_selector.modals.use_another_payment_method')}
        </PaymentLink>
      </>
    );
  };

  const getText = useGetText();
  const stepContent = [
    {
      stepTitle: getText('plans_selector.yearly_confirmation.modals.plan_end', {
        end_billing_cycle: currentCycleEndDate
      }),
      topics: [
        getText(
          'plans_selector.yearly_confirmation.modals.unused_monthly_plan'
        ),
        getText('plans_selector.yearly_confirmation.modals.unused_message'),
        getText('plans_selector.yearly_confirmation.modals.refunds')
      ]
    },
    {
      stepTitle: chargeStep()
    },
    {
      stepTitle: getText(
        'plans_selector.yearly_confirmation.modals.automatic_renew_information',
        {
          renew_date: nextCycleEndDate,
          yearly_plan_pages: String(selectedYearlyPlan.pages)
        }
      )
    }
  ];
  return (
    <div data-testid="yearly-plan-selector-arn-step">
      {!isTablet && <HeaderImage src={ARNImage} alt="ARN banner" />}
      <Container>
        <Title>
          {getText('plans_selector.yearly_confirmation.modals.confirm_title')}
        </Title>
        <Subtitle>
          {getText('plans_selector.yearly_confirmation.modals.what_expect')}
        </Subtitle>
        {stepContent.map((step, index) => (
          <Stepper
            key={index}
            index={index}
            stepTitle={step.stepTitle}
            topics={step.topics}
            isLastTopic={index === stepContent.length - 1}
          />
        ))}
        {shouldShowCvvCard && (
          <BillingCvv data-analyticsid="ii_plans_billing-cvv" />
        )}
        <DividingLine />
        <JarvisARN />
      </Container>
    </div>
  );
};
