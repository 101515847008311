import React from 'react';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { PlanCard } from '../PlanCard';
import { PlanCardYearly } from '../PlanCardYearly';
import {
  ANALYTICS_EVENT_LABEL,
  PLAN_PROGRAMMES
} from '../../../common/constants';
import { PlanCardContainer, PlanSelectorContainer } from './styles';
import { PaperCard } from '../PaperCard';
import { YearlyCard } from '../YearlyCard';

export const DashboardPlansContent: React.FC = () => {
  const {
    isYearlyPlanSelected,
    selectedPlan: { plan },
    plans,
    program,
    showPaperCard,
    showYearlyCard,
    enableYearlyPlan,
    yearlyPlans,
    trackClickEvent
  } = useDashboardPlansState();

  const showPaperOffer =
    showPaperCard && program == PLAN_PROGRAMMES.INSTANT_INK;

  if (isYearlyPlanSelected) {
    if (yearlyPlans) {
      return (
        <PlanCardContainer>
          <PlanSelectorContainer>
            {yearlyPlans.map((plan, index) => {
              return <PlanCard key={index} index={index} plan={plan} />;
            })}
            {showPaperOffer && !enableYearlyPlan && <PaperCard />}
          </PlanSelectorContainer>
          {showPaperOffer && enableYearlyPlan && <PaperCard />}
        </PlanCardContainer>
      );
    }

    trackClickEvent(ANALYTICS_EVENT_LABEL, 'yearly-Plan-Selected');
    return <PlanCardYearly index={2} plan={plan} />;
  }

  return (
    <PlanCardContainer>
      <PlanSelectorContainer>
        {showYearlyCard && <YearlyCard />}
        {plans.map((plan, index) => {
          return <PlanCard key={index} index={index} plan={plan} />;
        })}
        {showPaperOffer && !enableYearlyPlan && <PaperCard />}
      </PlanSelectorContainer>
      {showPaperOffer && enableYearlyPlan && <PaperCard />}
    </PlanCardContainer>
  );
};
